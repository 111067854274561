<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div>
          <section class="flex items-center space-x-4 mb-5">
            <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
              <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
            </vs-button>

            <h1 class="page_title">{{ $route.meta.title }}</h1>
          </section>
        </div>

        <main v-if="is_API_loaded" class="space-y-5">
          <!-- 新增會員後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
                <!-- <a :href="_wlink" target="_blank">在前台中檢視</a> 或 <router-link :to="{ name: 'menus' }">加入目錄選單</router-link> -->
              </vs-alert>
            </section>
          </transition>

          <!-- 2. 新增會員 -->
          <section v-if="isNewCustomer" class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <h3 class="h3">基本資料</h3>
                <div class="grid grid-cols-2 gap-4">
                  <div class="col-span-2">
                    <h1 class="title">姓名 *</h1>
                    <ValidationProvider tag="div" class="relative" name="姓名" rules="required|max:20" v-slot="{ errors }">
                      <vs-input placeholder="必填" v-model="customer.uname" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <h1 class="title">電話號碼</h1>
                    <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
                      <div class="sm:col-span-1">
                        <vs-select placeholder="請選擇區碼" filter v-model="phone_code1" v-if="countries.length > 0">
                          <vs-option
                            v-for="country in countries"
                            :key="country.en"
                            :label="`${country.code} (${country.cn} ${country.en})`"
                            :value="`${country.code},${country.en}`"
                            >{{ country.code }} ({{ country.cn }})</vs-option
                          >
                        </vs-select>
                      </div>

                      <div class="sm:col-span-2">
                        <ValidationProvider tag="div" class="relative" name="電話號碼" rules="numeric|max:20" v-slot="{ errors }">
                          <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="customer.mp" />
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h1 class="title">電子信箱 *</h1>
                    <ValidationProvider tag="div" class="relative" name="電子信箱" rules="required|max:150|email" v-slot="{ errors }">
                      <vs-input placeholder="必填" v-model="customer.email" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="flex items-center space-x-2 title">
                      <h1 class="">密碼 *</h1>
                      <vs-tooltip color="primary">
                        <svg-icon icon-class="caution" class="primary xs"></svg-icon>
                        <template #tooltip>替會員新增一組預設密碼，會員登入後可於會員中心內修改密碼。</template>
                      </vs-tooltip>
                    </div>
                    <ValidationProvider tag="div" class="relative" name="密碼" rules="min:8|max:16" v-slot="{ errors }">
                      <vs-input
                        type="password"
                        placeholder="必填"
                        :visiblePassword="hasVisiblePassword"
                        @click-icon="hasVisiblePassword = !hasVisiblePassword"
                        v-model="customer.pawd"
                      >
                        <template #icon>
                          <svg-icon v-if="customer.pawd === ''" icon-class="password" class="gray-text-light"></svg-icon>
                          <svg-icon v-else-if="hasVisiblePassword" icon-class="eye_hide" class="gray-text-light"></svg-icon>
                          <svg-icon v-else icon-class="eye_view" class="gray-text-light"></svg-icon>
                        </template>
                      </vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <h1 class="title">生日</h1>
                    <ValidationProvider tag="div" class="relative" name="生日" v-slot="{ errors }">
                      <vs-input type="date" :max="_today" v-model="customer.birthday" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <h1 class="title">性別</h1>
                    <vs-select v-model="customer.sex">
                      <vs-option label="不提供" :value="0">不提供</vs-option>
                      <vs-option label="男" :value="1">男</vs-option>
                      <vs-option label="女" :value="2">女</vs-option>
                    </vs-select>
                  </div>
                </div>
              </article>
              <article class="box overflow-hidden transition-all duration-200" :class="[addressContainer ? 'addressOpen' : 'addressClose']">
                <div class="flex items-center justify-between">
                  <h3 class="h3">主要地址</h3>
                  <transition name="fade">
                    <vs-button v-if="!addressContainer" @click="addressContainer = true" size="mini" transparent color="success">
                      <svg-icon icon-class="add" className="success"></svg-icon>
                      <span class="ml-1 font-medium text-success text-sm">新增地址</span>
                    </vs-button>
                  </transition>
                </div>
                <vs-checkbox v-model="sameAsTop"> 同會員資料 </vs-checkbox>
                <div class="grid grid-cols-2 gap-4">
                  <div class="col-span-2">
                    <h1 class="title">姓名</h1>
                    <ValidationProvider tag="div" class="relative" name="姓名" rules="max:30" v-slot="{ errors }">
                      <vs-input v-model="customer.runame" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <h1 class="title">電話號碼</h1>
                    <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
                      <div class="sm:col-span-1">
                        <vs-select placeholder="請選擇區碼" filter v-model="phone_code2" v-if="countries.length > 0">
                          <vs-option
                            v-for="country in countries"
                            :key="country.en"
                            :label="`${country.code} (${country.cn} ${country.en})`"
                            :value="`${country.code},${country.en}`"
                            >{{ country.code }} ({{ country.cn }})</vs-option
                          >
                        </vs-select>
                      </div>

                      <div class="sm:col-span-2">
                        <ValidationProvider tag="div" class="relative" name="電話號碼" rules="numeric|max:20" v-slot="{ errors }">
                          <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="customer.rmp" />
                          <errorDiv>{{ errors[0] }}</errorDiv>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-2">
                    <h1 class="title">電子信箱</h1>
                    <ValidationProvider tag="div" class="relative" name="電子信箱" rules="max:150|email" v-slot="{ errors }">
                      <vs-input v-model="customer.remail" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <h1 class="title">國家/地區</h1>
                    <vs-select placeholder="搜尋國家/地區" filter v-model="customer.rshit" v-if="countries.length > 0">
                      <vs-option
                        v-for="country in countries"
                        :key="country.en"
                        :label="country.cn + ' ' + country.en"
                        :value="country.cn + ' ' + country.en"
                        >{{ country.cn }} {{ country.en }}</vs-option
                      >
                    </vs-select>
                  </div>
                  <div>
                    <h1 class="title">城市</h1>
                    <ValidationProvider tag="div" class="relative" name="城市" rules="max:50" v-slot="{ errors }">
                      <vs-input v-model="customer.rcity" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <h1 class="title">郵遞區號</h1>
                    <ValidationProvider tag="div" class="relative" name="郵遞區號" rules="max:20" v-slot="{ errors }">
                      <vs-input v-model="customer.rzipcode" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <h1 class="title">地址一</h1>
                    <ValidationProvider tag="div" class="relative" name="地址一" rules="max:150" v-slot="{ errors }">
                      <vs-input v-model="customer.raddress1" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <h1 class="title">地址二</h1>
                    <ValidationProvider tag="div" class="relative" name="地址二" rules="max:150" v-slot="{ errors }">
                      <vs-input v-model="customer.raddress2" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </div>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h3 class="h3">會員等級</h3>
                <div>
                  <vs-select v-if="membershipList.length > 0" v-model="customer.vip" placeholder="選擇會員等級">
                    <vs-option value="不指定會員等級" label="不指定會員等級">不指定會員等級</vs-option>
                    <vs-option v-for="item in membershipList" :key="item.smvno" :value="item.smvno" :label="item.title">{{
                      item.title
                    }}</vs-option>
                  </vs-select>
                  <vs-select v-else disabled placeholder="尚無任何會員等級"> </vs-select>
                </div>
              </div>
              <div class="box">
                <h3 class="h3">購物金</h3>
                <div>
                  <ValidationProvider tag="div" class="relative" name="購物金" rules="numeric|min_value:0" v-slot="{ errors }">
                    <vs-input v-model.number="customer.point1" placeholder="0"></vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="box">
                <h3 class="h3">點數</h3>
                <div>
                  <ValidationProvider tag="div" class="relative" name="點數" rules="numeric|min_value:0" v-slot="{ errors }">
                    <vs-input v-model.number="customer.point2" placeholder="0"></vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="box">
                <h3 class="h3">訂閱行銷工具</h3>
                <div>
                  <vs-checkbox success v-model="customer.newsletter">同意訂閱行銷電子報</vs-checkbox>
                  <vs-checkbox success v-model="customer.sms">同意訂閱行銷簡訊</vs-checkbox>
                </div>
              </div>
              <div class="box">
                <h3 class="h3">備註</h3>
                <div>
                  <myTextarea placeholder="※ 會員看不到此資訊" height="100px" v-model="customer.note"></myTextarea>
                </div>
              </div>
            </div>
          </section>
          <!-- 2. 讀取會員 -->
          <section v-else class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div class="flex items-center justify-between">
                  <!-- <h3 class="h3">基本資料</h3> -->
                  <div class="flex space-x-2 items-center">
                    <!-- <svg-icon icon-class="member" class="primary"></svg-icon> -->
                    <h1 class="h1 font-bold">{{ uname }}</h1>
                  </div>
                  <vs-button @click="basic_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯基本資料</span>
                  </vs-button>
                </div>
                <div class="grid grid-cols-2 gap-4">
                  <!-- <div>
                  <h4 class="h4">消費總額</h4>
                  <h1 class="h1 font-bold ">NT$24,000</h1>
                </div>
                <div>
                  <h4 class="h4">消費次數</h4>
                  <h1 class="h1 font-bold ">3</h1>
                </div> -->
                  <div>
                    <h4 class="h4">電子信箱</h4>
                    <p class="break_word">{{ email }}</p>
                  </div>
                  <div>
                    <h4 class="h4">電話號碼</h4>
                    <p class="break_word">{{ mp }}</p>
                  </div>
                  <div v-if="sex !== 0">
                    <h4 class="h4">性別</h4>
                    <p v-if="sex === 1">男</p>
                    <p v-else-if="sex === 2">女</p>
                  </div>
                  <div v-if="birthday">
                    <h4 class="h4">生日</h4>
                    <p>{{ birthday }}</p>
                  </div>
                </div>
              </article>
              <article class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">訂單記錄</h3>
                  <vs-button v-if="customer.ordernum_arr.length > 0" size="mini" transparent color="success">
                    <router-link
                      :to="{
                        name: 'orders',
                        query: {
                          uname: email,
                        },
                      }"
                    >
                      <span class="font-medium text-success text-sm">查看全部</span></router-link
                    >
                  </vs-button>
                </div>
                <div v-if="customer.ordernum_arr.length > 0">
                  <vs-table class="max-h-96 overflow-y-scroll">
                    <template #thead>
                      <vs-tr>
                        <vs-th>訂單編號</vs-th>
                        <vs-th>總計</vs-th>
                        <vs-th>訂單狀態</vs-th>
                        <vs-th>付款狀態</vs-th>
                        <vs-th>出貨狀態</vs-th>
                        <vs-th>訂單日期</vs-th>
                      </vs-tr>
                    </template>
                    <template #tbody>
                      <vs-tr :key="tr.ordernum" v-for="tr in customer.ordernum_arr">
                        <vs-td>{{ tr.ordernum }}</vs-td>
                        <vs-td>NT${{ tr.opays | currency }}</vs-td>
                        <vs-td>
                          <p
                            class="rounded-full px-3 py-1 w-max"
                            :class="{
                              'bg-success text-white': tr.ostatus === 0,
                              'bg-danger text-white': tr.ostatus === 1,
                              'bg-gray-background text-gray-text_light': tr.ostatus === 2 || tr.ostatus === 3,
                            }"
                          >
                            {{ ostatusMixin(tr.ostatus) }}
                          </p>
                        </vs-td>
                        <vs-td
                          ><p
                            class="rounded-full px-3 py-1 w-max"
                            :class="{
                              'bg-warning text-white': tr.pstatus === -1,
                              'bg-danger text-white': tr.pstatus === 0,
                              'bg-success  text-white': tr.pstatus === 1 || tr.pstatus === 2,
                              'bg-gray-background text-gray-text_light': tr.pstatus === 3,
                            }"
                          >
                            {{ pstatusMixin(tr.pstatus) }}
                          </p></vs-td
                        >
                        <vs-td
                          ><p
                            class="rounded-full px-3 py-1 w-max"
                            :class="{
                              'bg-danger text-white': tr.lstatus === 0,
                              'bg-success text-white': tr.lstatus === 2 || tr.lstatus === 4,
                              'bg-gray-background text-gray-text_light': tr.lstatus === 3 || tr.lstatus === 6,
                              'bg-warning text-white': tr.lstatus === 1 || tr.lstatus === 5,
                            }"
                          >
                            {{ lstatusMixin(tr.lstatus) }}
                          </p></vs-td
                        >
                        <vs-td
                          ><p>{{ tr.odate.split(' ')[0] }}</p>
                          <p>{{ tr.odate.split(' ')[1] }}</p>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
                <div v-else style="min-height: 200px" class="flex items-center justify-center">
                  <p class="text-gray-divide text-xl">尚無訂單紀錄</p>
                </div>
              </article>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">購物金紀錄</h3>
                  <vs-button @click="point1_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯</span>
                  </vs-button>
                </div>
                <p v-if="point1_arr.Data.point1 === 0" class="text-gray-divide">尚無任何購物金</p>
                <p v-else>現有購物金: NT${{ point1_arr.Data.point1 | currency }}</p>
                <!-- 購物金列表 -->
                <div v-if="point1_arr.Data.arr.length > 0">
                  <vs-table>
                    <template #thead>
                      <vs-tr>
                        <vs-th></vs-th>
                        <vs-th> 更動款項 </vs-th>
                        <vs-th> 餘額 </vs-th>
                        <vs-th> 時間 </vs-th>
                      </vs-tr>
                    </template>
                    <template #tbody>
                      <vs-tr :key="i" v-for="(tr, i) in point1_arr.Data.arr">
                        <vs-td>
                          <vs-button size="mini" border success> 查看備註 </vs-button>
                        </vs-td>
                        <vs-td>
                          <span v-if="tr.point >= 0">+</span>
                          <span v-else>-</span>
                          <span>NT${{ Math.abs(tr.point) | currency }}</span></vs-td
                        >
                        <vs-td> NT${{ tr.bpoint | currency }} </vs-td>
                        <vs-td>
                          {{ tr.cdate }}
                        </vs-td>

                        <template #expand>
                          <div>
                            {{ tr.note }}
                          </div>
                        </template>
                      </vs-tr>
                    </template>
                    <template #footer>
                      <!-- 購物金 pagination -->
                      <div v-if="point1_arr.Page.tpages > 1" class="flex flex-col items-center space-y-2">
                        <vs-pagination
                          @click.native="changePagination('point1')"
                          not-arrows
                          v-model="point1_currentPage"
                          :length="point1_arr.Page.tpages"
                        />
                        <span class="text-xs text-gray-400">總共 {{ point1_arr.Page.pcounts }} 筆</span>
                      </div>
                    </template>
                  </vs-table>
                </div>
              </div>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">點數紀錄</h3>
                  <vs-button @click="point2_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯</span>
                  </vs-button>
                </div>
                <p v-if="point2_arr.Data.point2 === 0" class="text-gray-divide">尚無任何點數</p>
                <p v-else>現有點數: {{ point2_arr.Data.point2 }}點</p>
                <!-- 點數列表 -->
                <div v-if="point2_arr.Data.arr.length > 0">
                  <vs-table>
                    <template #thead>
                      <vs-tr>
                        <vs-th></vs-th>
                        <vs-th> 更動款項 </vs-th>
                        <vs-th> 餘額 </vs-th>
                        <vs-th> 時間 </vs-th>
                      </vs-tr>
                    </template>
                    <template #tbody>
                      <vs-tr :key="i" v-for="(tr, i) in point2_arr.Data.arr">
                        <vs-td>
                          <vs-button size="mini" border success> 查看備註 </vs-button>
                        </vs-td>
                        <vs-td>
                          <span v-if="tr.point >= 0">+</span>
                          <span>{{ tr.point }}</span>
                        </vs-td>
                        <vs-td>{{ tr.bpoint }} </vs-td>
                        <vs-td>
                          {{ tr.cdate }}
                        </vs-td>

                        <template #expand>
                          <div>
                            {{ tr.note }}
                          </div>
                        </template>
                      </vs-tr>
                    </template>
                    <template #footer>
                      <!--點數 pagination -->
                      <div v-if="point2_arr.Page.tpages > 1" class="flex flex-col items-center space-y-2">
                        <vs-pagination
                          @click.native="changePagination('point2')"
                          not-arrows
                          v-model="point2_currentPage"
                          :length="point2_arr.Page.tpages"
                        />
                        <span class="text-xs text-gray-400">總共 {{ point2_arr.Page.pcounts }} 筆</span>
                      </div>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">會員等級</h3>
                  <vs-button @click="memberShip_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯</span>
                  </vs-button>
                </div>
                <div>
                  <p class="break_word" :class="[vip === '不指定會員等級' ? 'text-gray-divide' : '']">{{ vip }}</p>
                </div>
              </div>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">主要地址</h3>
                  <vs-button @click="address_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯</span>
                  </vs-button>
                </div>
                <div>
                  <p class="break_word">{{ runame }}</p>
                  <p class="break_word">{{ rmp }}</p>
                  <p class="break_word">{{ remail }}</p>
                  <p class="break_word">{{ raddress1 }}</p>
                  <p class="break_word">{{ raddress2 }}</p>
                  <p class="break_word">{{ rarea }} {{ rzipcode }}</p>
                  <p class="break_word">{{ rcity }}</p>
                  <p class="break_word">{{ rshit }}</p>
                </div>
              </div>
              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">訂閱行銷工具</h3>
                  <vs-button @click="others_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯</span>
                  </vs-button>
                </div>
                <div>
                  <div v-if="newsletterStatus" class="flex items-center space-x-1">
                    <svg-icon icon-class="success" className="success"></svg-icon>
                    <p>已訂閱行銷電子報</p>
                  </div>
                  <div v-else class="flex items-center space-x-1">
                    <svg-icon icon-class="success" className="gray-divide"></svg-icon>
                    <p class="text-gray-divide">未訂閱行銷電子報</p>
                  </div>
                  <div v-if="smsStatus" class="flex items-center space-x-1">
                    <svg-icon icon-class="success" className="success"></svg-icon>
                    <p>已訂閱行銷簡訊</p>
                  </div>
                  <div v-else class="flex items-center space-x-1">
                    <svg-icon icon-class="success" className="gray-divide"></svg-icon>
                    <p class="text-gray-divide">未訂閱行銷簡訊</p>
                  </div>
                </div>
              </div>

              <div class="box">
                <div class="flex items-center justify-between">
                  <h3 class="h3">備註</h3>
                  <vs-button @click="note_Dialog = true" size="mini" transparent color="success">
                    <span class="font-medium text-success text-sm">編輯</span>
                  </vs-button>
                </div>
                <p v-if="note" class="whitespace-pre-line" v-html="note"></p>
                <p v-else class="text-gray-divide">尚無任何備註</p>
              </div>
              <div class="p-4">
                <!-- <h3 class="h3">登入紀錄</h3> -->
                <div>
                  <p v-if="customer.ldate">最後登入: {{ customer.ldate }}</p>
                  <p>註冊時間: {{ customer.cdate }}</p>
                </div>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="delete_dialog = true" v-if="!isNewCustomer" size="large" color="danger" border>
              <span class="font-medium">刪除會員</span>
            </vs-button>
            <router-link v-else :to="{ name: 'customers' }">
              <vs-button size="large" border>
                <span class="font-medium">取消</span>
              </vs-button>
            </router-link>
            <vs-button v-if="isNewCustomer" size="large" ref="newCustomer" :disabled="invalid" @click="newCustomer(invalid)">
              <span class="font-medium">新增會員</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>
    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除會員 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此會員？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的會員無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteCustomer" size="large" transparent color="danger" ref="deleteCustomerBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 編輯基本資料 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="basic_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯基本資料</h3>
        </template>

        <div class="grid grid-cols-2 gap-4 my-4">
          <div class="col-span-2">
            <h1 class="title">姓名 *</h1>
            <ValidationProvider tag="div" class="relative" name="姓名" rules="required|max:20" v-slot="{ errors }">
              <vs-input placeholder="必填" v-model="customer.uname" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div class="col-span-2">
            <h1 class="title">電話號碼</h1>
            <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
              <div class="sm:col-span-1">
                <vs-select placeholder="請選擇區碼" filter v-model="phone_code1" v-if="countries.length > 0">
                  <vs-option
                    v-for="country in countries"
                    :key="country.en"
                    :label="`${country.code} (${country.cn} ${country.en})`"
                    :value="`${country.code},${country.en}`"
                    >{{ country.code }} ({{ country.cn }})</vs-option
                  >
                </vs-select>
              </div>

              <div class="sm:col-span-2">
                <ValidationProvider tag="div" class="relative" name="電話號碼" rules="numeric|max:20" v-slot="{ errors }">
                  <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="customer.mp" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <h1 class="title">電子信箱 *</h1>
            <ValidationProvider tag="div" class="relative" name="電子信箱" rules="required|max:150|email" v-slot="{ errors }">
              <vs-input placeholder="必填" v-model="customer.email" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">生日</h1>
            <ValidationProvider tag="div" class="relative" name="生日" v-slot="{ errors }">
              <vs-input type="date" :max="_today" v-model="customer.birthday" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">性別</h1>
            <vs-select v-model="customer.sex">
              <vs-option label="不提供" :value="0">不提供</vs-option>
              <vs-option label="男" :value="1">男</vs-option>
              <vs-option label="女" :value="2">女</vs-option>
            </vs-select>
          </div>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="basic_Dialog = false">取消</vs-button>
            <vs-button @click="saveBasic(invalid)" :disabled="invalid" size="large" transparent color="success" ref="saveBasicBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯主要地址 dialog -->
    <vs-dialog overflow-hidden blur v-model="address_Dialog" not-center>
      <template #header>
        <h3 class="h3">編輯主要地址</h3>
      </template>

      <div class="grid grid-cols-2 gap-4 my-4">
        <div class="col-span-2">
          <h1 class="title">姓名</h1>
          <ValidationProvider tag="div" class="relative" name="姓名" rules="max:30" v-slot="{ errors }">
            <vs-input v-model="customer.runame" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
        <div class="col-span-2">
          <h1 class="title">電話號碼</h1>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div class="sm:col-span-1">
              <vs-select placeholder="請選擇區碼" filter v-model="phone_code2" v-if="countries.length > 0">
                <vs-option
                  v-for="country in countries"
                  :key="country.en"
                  :label="`${country.code} (${country.cn} ${country.en})`"
                  :value="`${country.code},${country.en}`"
                  >{{ country.code }} ({{ country.cn }})</vs-option
                >
              </vs-select>
            </div>

            <div class="sm:col-span-2">
              <ValidationProvider tag="div" class="relative" name="電話號碼" rules="numeric|max:20" v-slot="{ errors }">
                <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="customer.rmp" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <h1 class="title">電子信箱</h1>
          <ValidationProvider tag="div" class="relative" name="電子信箱" rules="max:150|email" v-slot="{ errors }">
            <vs-input v-model="customer.remail" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
        <div class="">
          <h1 class="title">國家/地區</h1>
          <vs-select placeholder="搜尋國家/地區" filter v-model="customer.rshit" v-if="countries.length > 0">
            <vs-option
              v-for="country in countries"
              :key="country.en"
              :label="country.cn + ' ' + country.en"
              :value="country.cn + ' ' + country.en"
              >{{ country.cn }} {{ country.en }}</vs-option
            >
          </vs-select>
        </div>
        <div>
          <h1 class="title">縣市</h1>

          <!-- 縣市(若為台灣，選單) -->
          <ValidationProvider
            v-if="customer.rshit === '台灣 Taiwan'"
            tag="div"
            class="relative"
            name="縣市"
            rules="required|max:20"
            v-slot="{ errors }"
          >
            <vs-select @change="cityHandler" class="w-full" v-model="customer.rcity">
              <vs-option v-for="city in TW_districts" :key="city.name" :label="city.name" :value="city.name">{{ city.name }}</vs-option>
            </vs-select>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>

          <ValidationProvider v-else tag="div" class="relative" name="縣市" rules="max:20" v-slot="{ errors }">
            <vs-input v-model="customer.rcity" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
        <div>
          <h1 class="title">鄉鎮市區</h1>

          <ValidationProvider
            v-if="_district && customer.rshit === '台灣 Taiwan'"
            tag="div"
            class="relative"
            name="鄉鎮市區"
            rules="required|max:20"
            v-slot="{ errors }"
          >
            <!-- 鄉鎮市區(若為台灣，選單) -->
            <vs-select :key="_district.districts.length" @change="districtHandler" class="w-full" v-model="customer.rarea">
              <vs-option v-for="district in _district.districts" :key="district.name" :label="district.name" :value="district.name">{{
                district.name
              }}</vs-option>
            </vs-select>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>

          <ValidationProvider v-else tag="div" class="relative" name="鄉鎮市區" rules="max:20" v-slot="{ errors }">
            <vs-input v-model="customer.rarea" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
        <div>
          <h1 class="title">郵遞區號</h1>
          <ValidationProvider tag="div" class="relative" name="郵遞區號" rules="max:20" v-slot="{ errors }">
            <vs-input v-model="customer.rzipcode" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
        <div class="col-span-2">
          <h1 class="title">地址一</h1>
          <ValidationProvider tag="div" class="relative" name="地址一" rules="max:100" v-slot="{ errors }">
            <vs-input v-model="customer.raddress1" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
        <div class="col-span-2">
          <h1 class="title">地址二</h1>
          <ValidationProvider tag="div" class="relative" name="地址二" rules="max:100" v-slot="{ errors }">
            <vs-input v-model="customer.raddress2" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="address_Dialog = false">取消</vs-button>
          <vs-button @click="saveAddress" size="large" transparent color="success" ref="saveAddressBtn">儲存</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 編輯訂閱行銷工具 dialog -->
    <vs-dialog overflow-hidden blur v-model="others_Dialog" not-center>
      <template #header>
        <h3 class="h3">編輯訂閱行銷工具</h3>
      </template>

      <div class="my-4">
        <vs-checkbox success v-model="customer.newsletter">同意訂閱行銷電子報</vs-checkbox>
        <vs-checkbox success v-model="customer.sms">同意訂閱行銷簡訊</vs-checkbox>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="others_Dialog = false">取消</vs-button>
          <vs-button @click="saveOthers" size="large" transparent color="success" ref="saveOthersBtn">儲存</vs-button>
        </div>
      </template>
    </vs-dialog>
    <!-- 編輯備註 dialog -->
    <vs-dialog overflow-hidden blur v-model="note_Dialog" not-center>
      <template #header>
        <h3 class="h3">編輯備註</h3>
      </template>

      <div class="my-4 text-sm">
        <myTextarea placeholder="※ 會員看不到此資訊" height="100px" v-model="customer.note"></myTextarea>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="note_Dialog = false">取消</vs-button>
          <vs-button @click="saveNote" size="large" transparent color="success" ref="saveNoteBtn">儲存</vs-button>
        </div>
      </template>
    </vs-dialog>
    <!-- 編輯會員等級 dialog -->
    <vs-dialog overflow-hidden blur v-model="memberShip_Dialog" not-center>
      <template #header>
        <h3 class="h3">編輯會員等級</h3>
      </template>

      <div class="my-4">
        <vs-select v-if="membershipList.length > 0" v-model="customer.smvno" placeholder="選擇會員等級">
          <vs-option value="不指定會員等級" label="不指定會員等級">不指定會員等級</vs-option>
          <vs-option v-for="item in membershipList" :key="item.smvno" :value="item.smvno" :label="item.title">{{ item.title }}</vs-option>
        </vs-select>
        <vs-select v-else disabled placeholder="尚無任何會員等級"> </vs-select>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="memberShip_Dialog = false">取消</vs-button>
          <vs-button @click="saveMembership" size="large" transparent color="success" ref="saveMembershipBtn">儲存</vs-button>
        </div>
      </template>
    </vs-dialog>
    <!-- 編輯購物金 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="point1_Dialog" not-center>
        <template #header>
          <div class="flex items-center justify-between">
            <h3 class="h3">編輯購物金</h3>
            <p class="text-sm text-gray-text_light">現有購物金: NT${{ point1_arr.Data.point1 | currency }}</p>
          </div>
        </template>

        <div class="my-4 space-y-4">
          <div>
            <h4 class="h4 mb-1">增減金額 *</h4>
            <p class="text-xs text-gray-400 mb-1">※ 例：新增500元，請輸入500；扣除300元，請輸入-300</p>
            <ValidationProvider
              tag="div"
              class="relative w-full"
              name="增減金額"
              :rules="`required|integer|min_value:-${point1_arr.Data.point1}`"
              v-slot="{ errors }"
            >
              <vs-input class="w-full" type="number" v-model.number="point1_input" placeholder=""></vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h4 class="h4 mb-1">備註 *</h4>
            <ValidationProvider tag="div" class="relative" name="備註" rules="required|max:50" v-slot="{ errors }">
              <vs-input v-model.number="point1_note" placeholder="請輸入此次更動的原因。會員會看到此資訊"></vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="point1_Dialog = false">取消</vs-button>
            <vs-button :disabled="invalid" @click="savePoint1(invalid)" size="large" transparent color="success" ref="savePoint1Btn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>
    <!-- 編輯點數 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="point2_Dialog" not-center>
        <template #header>
          <div class="flex items-center justify-between">
            <h3 class="h3">編輯購物金</h3>
            <p class="text-sm text-gray-text_light">現有點數: {{ point2_arr.Data.point2 }}點</p>
          </div>
        </template>

        <div class="my-4 space-y-4">
          <div>
            <h4 class="h4 mb-1">增減點數 *</h4>
            <p class="text-xs text-gray-400 mb-1">※ 例：新增500點，請輸入500；扣除300點，請輸入-300</p>
            <ValidationProvider
              tag="div"
              class="relative w-full"
              name="增減點數"
              :rules="`required|integer|min_value:-${point2_arr.Data.point2}`"
              v-slot="{ errors }"
            >
              <vs-input class="w-full" type="number" v-model.number="point2_input"></vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h4 class="h4 mb-1">備註 *</h4>
            <ValidationProvider tag="div" class="relative" name="備註" rules="required|max:50" v-slot="{ errors }">
              <vs-input v-model.number="point2_note" placeholder="請輸入此次更動的原因。會員會看到此資訊"></vs-input>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="point2_Dialog = false">取消</vs-button>
            <vs-button :disabled="invalid" @click="savePoint2(invalid)" size="large" transparent color="success" ref="savePoint2Btn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import TW_districts from '@/assets/json/TW_districts.json' // 台灣縣市 郵遞區號 列表
import countries from '@/assets/json/en_zh_country.json'

import orderStatus from '@/assets/mixins/orderStatus.js'
export default {
  name: 'customer',
  mixins: [orderStatus],
  data() {
    return {
      TW_districts: TW_districts, // 台灣縣市 郵遞區號 列表
      is_API_loaded: false,
      isNewCustomer: false,
      delete_dialog: false,
      countries: countries,
      sameAsTop: false,
      phone_code1: '+886,Taiwan',
      phone_code2: '+886,Taiwan',
      hasVisiblePassword: true, // 是否開啟密碼預覽
      alertAfterCreated: false,
      alertTitle: '',
      addressContainer: false,
      basic_Dialog: false,
      address_Dialog: false,
      others_Dialog: false,
      note_Dialog: false,
      memberShip_Dialog: false,
      point1_Dialog: false,
      point2_Dialog: false,
      // 購物金列表
      point1_arr: {
        Data: {
          point1: 0,
          arr: [],
        },
      },
      // 點數列表
      point2_arr: {
        Data: {
          point2: 0,
          arr: [],
        },
      },
      order_currentPage: 1, // 訂單記錄分頁
      point1_input: '', // 修改購物金時，增減輸入框
      point1_note: '', // 修改購物金時，備註輸入框
      point1_currentPage: 1,
      point2_input: '', // 修改點數時，增減輸入框
      point2_note: '', // 修改點數時，備註輸入框
      point2_currentPage: 1,
      customer: {
        email: '',
        mp: '',
        vip: '不指定會員等級',
        uname: '',
        birthday: '',
        sex: 0,
        point1: '',
        point2: '',
        newsletter: false,
        sms: false,
        ldate: '',
        cdate: '',
        pawd: '00000000',
        note: '',
        ordernum_arr: [],
        // 地址
        runame: '',
        rmp: '',
        remail: '',
        rshit: '',
        rcity: '',
        rarea: '',
        rzipcode: '',
        raddress1: '',
        raddress2: '',
      },
      membershipList: [],
      // 在讀取時才修改
      newsletterStatus: false,
      smsStatus: false,
      note: '',
      email: '',
      mp: '',
      vip: '',
      uname: '',
      birthday: '',
      sex: '',
      point1: '',
      point2: '',
      // 地址
      runame: '',
      rmp: '',
      remail: '',
      rshit: '',
      rcity: '',
      rarea: '',
      rzipcode: '',
      raddress1: '',
      raddress2: '',
    }
  },
  computed: {
    // 篩選出鄉鎮市區
    _district() {
      let city = this.customer.rcity

      if (city) return this.TW_districts.find((item) => item.name === city)
      else return null
    },
    // 今天日期
    _today() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()

      function plusZero(value) {
        if (value < 10) return '0' + value
        else return value
      }

      return `${year}-${plusZero(month)}-${plusZero(day)}`
    },
  },
  watch: {
    $route(to, from) {
      this.isNewCustomerFunc()
    },
    // 去除首字 0
    'customer.mp'(value) {
      if (value.substr(0, 1) === '0') {
        this.customer.mp = value.slice(1)
      }
    },
    // 去除首字 0
    'customer.rmp'(value) {
      if (value.substr(0, 1) === '0') {
        this.customer.rmp = value.slice(1)
      }
    },
    // 同會員資料
    sameAsTop(value) {
      if (value) {
        this.customer.runame = this.customer.uname
        this.phone_code2 = this.phone_code1
        this.customer.rmp = this.customer.mp
        this.customer.remail = this.customer.email
      }
    },
  },
  async created() {
    // 無論是不是新頁面都要讀取
    const res1 = await this.loadMemberShip()

    if (this.$route.name === 'new-customer') {
      this.isNewCustomer = true
      if (res1) this.is_API_loaded = true
    } else {
      this.isNewCustomer = false
      const res = await Promise.all([this.loadCustomer(), this.loadPoint1(), this.loadPoint2()])
      if (res1 && res.every((e) => e === true)) {
        this.is_API_loaded = true
      }
    }
  },
  methods: {
    // 3.2.2.1 讀取會員等級列表
    loadMemberShip() {
      return this.$axios({
        url: 'front/store/mvip/getMVipList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.membershipList = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 3.2.1.2 會員資料-讀取
    loadCustomer() {
      return this.$axios({
        url: 'front/store/member/getMemberUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data
          if (data.birthday === '1911-01-01') data.birthday = ''

          this.mp = data.mp
          this.rmp = data.rmp

          if (data.mp) {
            for (let i = 5; i > 1; i--) {
              // 迴圈四次 i =  5,4,3,2
              let confirm = false
              let code = data.mp.substr(0, i) // 取得+區碼四位 例如 +1876 牙買加
              this.countries.forEach((country) => {
                if (country.code === code) {
                  confirm = true
                  this.phone_code1 = `${country.code},${country.en}` // 取得配對到的國家
                  data.mp = data.mp.replace(code, '') // 去除區碼
                }
              })
              if (confirm) {
                break
              }
            }
          }
          if (data.rmp) {
            for (let i = 5; i > 1; i--) {
              // 迴圈四次 i =  5,4,3,2
              let confirm = false
              let code = data.rmp.substr(0, i) // 取得+區碼四位 例如 +1876 牙買加
              this.countries.forEach((country) => {
                if (country.code === code) {
                  confirm = true
                  this.phone_code2 = `${country.code},${country.en}` // 取得配對到的國家
                  data.rmp = data.rmp.replace(code, '') // 去除區碼
                }
              })
              if (confirm) {
                break
              }
            }
          }

          if (data.newsletter) {
            data.newsletter = true
            this.newsletterStatus = true
          } else {
            data.newsletter = false
            this.newsletterStatus = false
          }
          if (data.sms) {
            data.sms = true
            this.smsStatus = true
          } else {
            data.sms = false
            this.smsStatus = false
          }
          if (data.vip === '無') data.vip = '不指定會員等級'
          if (data.smvno === '無') data.smvno = '不指定會員等級'

          this.note = data.note
          this.email = data.email
          this.vip = data.vip
          this.uname = data.uname
          this.birthday = data.birthday
          this.sex = data.sex

          this.runame = data.runame
          this.remail = data.remail
          this.rshit = data.rshit
          this.rcity = data.rcity
          this.rarea = data.rarea
          this.rzipcode = data.rzipcode
          this.raddress1 = data.raddress1
          this.raddress2 = data.raddress2

          this.customer = data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    // 3.2.1.3 會員資料-新增
    newCustomer(invalid) {
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.newCustomer,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/setMemberData.php',
        headers: {
          pawd: this.customer.pawd,
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          email: this.customer.email,
          mp: this.customer.mp ? this.phone_code1.split(',')[0] + this.customer.mp : '',
          uname: this.customer.uname,
          birthday: this.customer.birthday,
          sex: this.customer.sex,
          runame: this.customer.runame,
          rmp: this.customer.rmp ? this.phone_code2.split(',')[0] + this.customer.rmp : '',
          remail: this.customer.remail,
          rshit: this.customer.rshit,
          rcity: this.customer.rcity,
          rarea: this.customer.rarea,
          rzipcode: this.customer.rzipcode,
          raddress1: this.customer.raddress1,
          raddress2: this.customer.raddress2,
          sms: this.customer.sms ? 1 : 0,
          newsletter: this.customer.newsletter ? 1 : 0,
          note: this.customer.note,
          point1: this.customer.point1 === '' ? 0 : this.customer.point1,
          point2: this.customer.point2 === '' ? 0 : this.customer.point2,
          vip: this.customer.vip === '不指定會員等級' ? '' : this.customer.vip,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.alertAfterCreated = true // 呈現alert
          this.alertTitle = this.customer.uname
          this.$router.replace({
            // 跳轉到新頁面
            name: 'edit-customer',
            params: {
              id: res.data.Data.smno,
            },
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.2.1.4 會員資料-修改基本資料
    saveBasic(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBasicBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/uMemberData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smno: this.$route.params.id,
          email: this.customer.email,
          mp: this.customer.mp ? this.phone_code1.split(',')[0] + this.customer.mp : '',
          uname: this.customer.uname,
          birthday: this.customer.birthday,
          sex: this.customer.sex,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.basic_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadCustomer()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.2.1.5 會員資料-修改收件人地址
    saveAddress() {
      const loading = this.$vs.loading({
        target: this.$refs.saveAddressBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/uMemberRData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smno: this.$route.params.id,
          rmp: this.customer.rmp ? this.phone_code2.split(',')[0] + this.customer.rmp : '',
          runame: this.customer.runame,
          rshit: this.customer.rshit,
          remail: this.customer.remail,
          rcity: this.customer.rcity,
          rarea: this.customer.rarea,
          rzipcode: this.customer.rzipcode,
          raddress1: this.customer.raddress1,
          raddress2: this.customer.raddress2,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.address_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadCustomer()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.2.1.6 會員資料-修改其他(電子報)
    saveOthers() {
      const loading = this.$vs.loading({
        target: this.$refs.saveOthersBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/uMemberOther.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smno: this.$route.params.id,
          sms: this.customer.sms ? 1 : 0,
          newsletter: this.customer.newsletter ? 1 : 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.others_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadCustomer()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.2.1.7 會員資料-修改備註
    saveNote() {
      const loading = this.$vs.loading({
        target: this.$refs.saveNoteBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/uMemberNote.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smno: this.$route.params.id,
          note: this.customer.note,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.note_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadCustomer()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.2.1.9 會員資料-修改會員等級
    saveMembership() {
      const loading = this.$vs.loading({
        target: this.$refs.saveMembershipBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/uMemberMVip.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smno: this.$route.params.id,
          smvno: this.customer.smvno === '不指定會員等級' ? '' : this.customer.smvno,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.memberShip_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadCustomer()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.2.1.10 會員資料-刪除
    deleteCustomer() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteCustomerBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/member/dMemberData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          setTimeout(() => {
            this.$router.replace({
              name: 'customers',
            })
          }, 0)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 3.2.3.3 讀取會員購物金列表
    loadPoint1() {
      return this.$axios({
        url: 'front/store/point1/getPoint1List.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: this.point1_currentPage,
          smno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.point1_arr = {
            Data: {
              point1: 0,
              arr: [],
            },
          } // 須先清空，不然換頁會有bug
          setTimeout(() => {
            this.point1_arr = res.data
          }, 0)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
        return res.data.Success
      })
    },
    // 3.2.3.4 會員購物金資料-新增
    savePoint1(invalid) {
      if (invalid || this.point1_input === 0) return

      const loading = this.$vs.loading({
        target: this.$refs.savePoint1Btn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/point1/setPoint1Data.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this.$route.params.id,
          point: this.point1_input,
          note: this.point1_note,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadPoint1()
          loading.close()
          this.point1_Dialog = false
          this.point1_input = ''
          this.point1_note = ''
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 3.2.4.3 讀取會員點數列表
    loadPoint2() {
      return this.$axios({
        url: 'front/store/point2/getPoint2List.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: this.point2_currentPage,
          smno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.point2_arr = {
            Data: {
              point2: 0,
              arr: [],
            },
          } // 須先清空，不然換頁會有bug
          setTimeout(() => {
            this.point2_arr = res.data
          }, 0)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
        return res.data.Success
      })
    },
    // 3.2.4.4 會員點數資料-新增
    savePoint2(invalid) {
      if (invalid || this.point2_input === 0) return

      const loading = this.$vs.loading({
        target: this.$refs.savePoint2Btn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/point2/setPoint2Data.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smno: this.$route.params.id,
          point: this.point2_input,
          note: this.point2_note,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadPoint2()
          loading.close()
          this.point2_Dialog = false
          this.point2_input = ''
          this.point2_note = ''
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 改變頁籤
    changePagination(name) {
      // name : point1 / point2 / order
      switch (name) {
        case 'point1':
          this.loadPoint1()
          break
        case 'point2':
          this.loadPoint2()
          break
        case 'order':
          console.log(name)
          break
      }
    },
    // 選擇縣市時，改變鄉鎮市區和郵遞區號
    cityHandler(e) {
      const city = e
      let cityObj = this.TW_districts.find((item) => item.name === city)
      this.customer.rarea = cityObj.districts[0].name
      this.customer.rzipcode = cityObj.districts[0].zip
    },
    // 選擇鄉鎮市區時，改變郵遞區號
    districtHandler(e) {
      const city = this.customer.rcity
      let obj = this.TW_districts.find((item) => item.name === city).districts.find((district) => district.name === e)
      this.customer.rzipcode = obj.zip
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-4;
}

.title {
  @apply text-sm  text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base  text-gray-text_dark;
}

.addressClose {
  max-height: calc(32px + 28.4px);
}

.addressOpen {
  max-height: 100%;
}

::v-deep .vs-input__icon {
  background: white !important;
  box-shadow: none !important;
  left: 1px !important;
}

::v-deep .vs-alert__title {
  height: 100%;
}

::v-deep .vs-alert__content {
  min-height: 0px;
}
</style>
